import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BasicPageTemplate from '../components/BasicPageTemplate'
import { HTMLContent } from '../components/Content'

const BasicPageAside = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BasicPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        asideImages={post.frontmatter.asideImages}
        notice={post.frontmatter.notice}
      />
    </Layout>
  )
}

BasicPageAside.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BasicPageAside

export const basicAsidePageQuery = graphql`
  query BasicPageAside($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        notice {
          title
          message
        }
        asideImages {
          image {
            childImageSharp {
              fixed (width: 500, height: 1000, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          alt
        }
      }
    }
  }
`
